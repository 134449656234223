@font-face {
  font-family: 'Musetta';
  src: url('./fonts/musetta-regular-webfont.woff2') format('woff2'), url('./fonts/musetta-regular-webfont.woff') format('woff'), url('./fonts/musetta-regular-webfont.ttf') format('truetype');
}
@font-face {
  font-family: 'Cassannet';
  src: url('./fonts/cassannet_plus_black-webfont.woff2') format('woff2'), url('./fonts/cassannet_plus_black-webfont.woff') format('woff'), url('./fonts/cassannet_plus_black-webfont.ttf') format('truetype');
}
@font-face {
  font-family: 'Bariol Thin';
  src: url('./fonts/bariol_thin-webfont.woff2') format('woff2'), url('./fonts/bariol_thin-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Bariol Serif Thin';
  src: url('./fonts/bariol_serif_thin-webfont.woff2') format('woff2'), url('./fonts/bariol_serif_thin-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Archia';
  src: url('./fonts/archia-thin-webfont.woff2') format('woff2'), url('./fonts/archia-thin-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Bould';
  src: url('./fonts/bould-bold-webfont.woff2') format('woff2'), url('./fonts/bould-bold-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Geomanist';
  src: url('./fonts/geomanist-ultra-webfont.woff2') format('woff2'), url('./fonts/geomanist-ultra-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Knile Black';
  src: url('./fonts/knile-black-webfont.woff2') format('woff2'), url('./fonts/knile-black-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Noway Light';
  src: url('./fonts/noway-light-webfont.woff2') format('woff2'), url('./fonts/noway-light-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Parking';
  src: url('./fonts/parking-regular-webfont.woff2') format('woff2'), url('./fonts/parking-regular-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Salome';
  src: url('./fonts/salome-webfont.woff2') format('woff2'), url('./fonts/salome-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Silka';
  src: url('./fonts/silka-black-webfont.woff2') format('woff2'), url('./fonts/silka-black-webfont.woff') format('woff');
}
@font-face {
  font-family: 'Wotfard';
  src: url('./fonts/wotfard-bold-webfont.woff2') format('woff2');
}
body {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
body a {
  cursor: pointer;
}
body button {
  cursor: pointer;
}
body select {
  cursor: pointer;
}
body input {
  font-size: 16px;
}
body select {
  font-size: 16px;
}
.linkListDisplayHeader {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: whitesmoke;
}
a:link {
  color: black;
  font-weight: 700;
  text-decoration: none;
  -webkit-user-select: none;
  /* disable selection/Copy of UIWebView */
  -webkit-touch-callout: none;
  /* disable the IOS popup when long-press on a link */
}
a:hover {
  color: black;
  text-decoration: underline;
}
a:active {
  color: black;
  background-color: grey;
  text-decoration: underline;
}
a:visited {
  color: lightslategray;
  text-decoration: underline;
}
.homepageImageHolder {
  flex-direction: row;
  width: 100%;
  flex-wrap: nowrap;
}
.homepageImagePhoto {
  width: 100%;
  max-width: 1000px;
}
.signupcards {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
}
.landingIMG {
  max-width: 800px;
  width: 50%;
}
.unionBug {
  max-width: 450px;
  width: 33%;
}
.App {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.linkList {
  display: grid;
  height: 100vh;
  width: 100%;
  grid-template-rows: auto 1fr auto;
}
header {
  overflow: hidden;
  font-family: 'Mulish', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', sans-serif;
  padding-top: 2.5vh;
  font-weight: 900;
  max-height: 25vh;
  height: 100%;
  border-bottom: 0.25vh solid black;
  background-color: whitesmoke;
}
header span {
  cursor: pointer;
}
header img {
  max-height: 10vh;
}
header .picHolder {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
header h1 {
  font-size: 2em;
}
header .drawer {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  color: white;
  background-image: url("https://imagizer.imageshack.com/img923/9862/ZNI9Nh.jpg");
  background-size: cover;
  background-position: top;
}
header .drawer h2 {
  padding-bottom: 2vh;
  padding-top: 3vh;
}
.specialText {
  font-family: 'Salome', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 2.2em;
  margin: 1em;
}
.semiSpecialText {
  font-family: 'Wotfard', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 0.7em;
  color: grey;
  display: none;
}
.normalText {
  font-family: 'Wotfard', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.1em;
}
.backColorPickerDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.showingURLDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.textColorPickerDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.fontPickerDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.easyAddInstaDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}
.easyAddPixelDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
}
.infoDisplayDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
  font-family: 'Wotfard', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.1em;
}
.availabilityDisplayDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
  font-family: 'Wotfard', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.1em;
}
.csDisplayDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
  font-family: 'Wotfard', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.1em;
}
.ssDisplayDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
  font-family: 'Wotfard', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.1em;
}
.statsDisplayDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease-out;
}
.statsHPDisplayDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease-out;
  font-family: 'Wotfard', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.1em;
}
.landingPanel {
  font-family: 'Wotfard', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.3em;
  color: grey;
}
.carbonDisplayDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
  font-family: 'Wotfard', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.1em;
}
.profPicChangerDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.profPicUploadDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.addwithPhotoDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
}
.bgPicUploadDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.4s ease-out;
}
#imgPreview {
  max-width: 250px;
  max-height: 250px;
}
#headerName {
  word-break: break-word;
}
#thecanvas {
  max-width: 250px;
  max-height: 250px;
  display: none;
}
.displayNameChangerDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.passwordChangerDiv {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.vicPie {
  height: 225px;
  padding: 5%;
}
.vicLine {
  width: 500px;
  height: 560px;
  margin: 0 auto;
  padding: 5%;
}
.vicBar {
  height: 225px;
  padding: 5%;
}
.entryChartHolder {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}
.qrcode {
  display: none;
  transition: max-height 0.2s ease-out;
  padding: 18px;
}
main {
  margin-top: 25vh;
  width: 100%;
  max-width: 800px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  margin: 0 auto;
  padding-top: 1.75vh;
  font-family: 'Mulish', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', sans-serif;
  font-weight: 400;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: min-content;
  justify-content: center;
  align-items: flex-start;
  background-image: linear-gradient(70deg, white, darkGray);
  background-size: contain;
  background-position: top;
  background-attachment: fixed;
}
main a {
  font-family: 'Mulish', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', sans-serif;
  font-weight: 700;
  display: block;
}
main p {
  display: block;
  line-height: 1.1;
  white-space: pre-wrap;
}
main img {
  padding: 1.5%;
  max-height: 165px;
  max-width: 165px;
}
.addLinkPreviewImage {
  max-height: 45px;
  max-width: 165px;
}
main div {
  border: 2px solid black;
  width: 80%;
  margin: 1.5vh auto;
  border-radius: 1vh;
  flex: 1 1 450px;
  display: flex;
  flex-direction: column;
  padding: 1.5vh;
}
div.advertisement > div {
  border: none!important;
  width: 100%!important;
  /* margin: 0px!important; */
  padding: 0px!important;
}
div.advertisement > div > div {
  border: none!important;
  width: 100%!important;
  margin: 0px!important;
  padding: 0px!important;
}
div.advertisement > div > div > div {
  border: none!important;
  width: 100%!important;
  margin: 0px!important;
  padding: 0px!important;
}
main div .linkSquare {
  min-height: 205px;
  height: 100%;
  background-color: white;
}
main div .linkDescription {
  overflow: hidden;
}
main div .linkDescription {
  width: 90%;
  text-align: left;
  padding: 0 2px;
  font-size: small;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  word-wrap: break-word;
}
main div .linkDescriptionTag {
  padding-top: 0.8vh;
  padding-bottom: 0.8vh;
  cursor: pointer;
}
.editLinkDescription {
  width: 60%;
  min-height: 20vh;
  text-align: center;
}
.linkSquare {
  background-color: whitesmoke;
}
.linkSquare a:link {
  border-bottom: 1px solid green;
}
.linkSquare a:visited {
  border-bottom: 1px solid red;
}
.linkSquare .linkSquareButtonHolder {
  display: flex;
  flex-direction: row;
  max-height: 2.25em;
  justify-content: space-evenly;
  align-items: center;
}
.linkSquare .linkSquareButtonHolder a:link {
  border-bottom: none;
}
.linkSquare .linkSquareButtonHolder a:hover {
  background-color: darkgray;
}
.linkSquare .linkSquareButtonHolder a:visited {
  border-bottom: none;
}
.linkSquare .sqaureButton:hover {
  background-color: darkgray;
}
.linkSquare:last-child {
  margin-bottom: 10vh;
}
footer {
  overflow: hidden;
  font-family: 'Mulish', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', sans-serif;
  font-weight: 900;
  border-top: 0.25vh solid black;
}
footer span {
  cursor: pointer;
}
.footerLink {
  font-weight: 900;
  color: white;
}
.active {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
}
.dashboard2 header {
  max-height: 70vh;
}
.dashboard2 tspan {
  color: #252525;
  fill: #252525;
}
.dashboard2 hr {
  background-color: black;
  border-color: black;
  width: 100%;
}
.colorPickerHolder {
  margin: 5px auto;
  width: 220px;
}
.linkListDashboardHeader {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.linkListDashboardHeader header {
  max-height: 35vh;
}
.linkListDashboardHeader .dashboardToggleHolder {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
}
.linkListDashboardHeader .dashboardToggleHolder p {
  border: 1px solid black;
  padding: 1em;
  margin: 1em;
  cursor: pointer;
}
.linkListDashboardHeader .dashboardToggleHolder p:hover {
  background-color: darkgray;
  cursor: pointer;
}
.settingsPanel span {
  cursor: pointer;
}
.settingsPanel button {
  cursor: pointer;
}
.settingsPanel a {
  cursor: pointer;
}
.dashboardInfoSection {
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em;
  text-align: center;
  word-break: break-all;
}
.dashboardInfoSection .sqaureButton {
  word-break: break-word;
}
.dashboardInfoSection span {
  cursor: pointer;
}
.dashboardInfoSection h2 {
  font-weight: bolder;
}
.dashboardInfoSection table {
  margin: 0 auto;
  border: 1px solid black;
  padding: 1em;
  border-collapse: collapse;
  width: 80%;
}
.dashboardInfoSection table td {
  border: 1px solid black;
  padding: 1em;
  word-break: break-word;
}
.darkMode {
  background-color: black;
}
.darkMode a:link {
  color: white;
}
.darkMode a:hover {
  color: white;
}
.darkMode a:active {
  color: white;
}
.darkMode a:visited {
  color: lightslategray;
}
.darkMode header {
  border-bottom: 0.25vh solid grey;
  background-color: black;
  color: white;
}
.darkMode header img {
  border-radius: 1vh;
  padding: 0.25vh;
  border: 0.25vh solid grey;
}
.darkMode main {
  background-image: linear-gradient(70deg, #151515, black);
  background-size: contain;
  background-position: top;
}
.darkMode main div {
  border: 2px solid grey;
  background-color: black;
  color: white;
}
.darkMode main div p {
  color: grey;
}
.darkMode main div img {
  background-color: dimgrey;
  border-radius: 1vh;
}
.darkMode .linkList {
  background-color: black;
}
.darkMode .drawer {
  color: white;
}
.darkMode .dashboard2 hr {
  background-color: white;
  border-color: white;
}
.darkMode .dashboard2 tspan {
  fill: #ffffff;
  color: #ffffff;
}
.darkMode .linkListDashboardHeader .dashboardToggleHolder {
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  overflow: hidden;
}
.darkMode .linkListDashboardHeader .dashboardToggleHolder p {
  border: 1px solid white;
  cursor: pointer;
}
.darkMode .linkListDashboardHeader .dashboardToggleHolder p:hover {
  background-color: darkgray;
  cursor: pointer;
}
.darkMode .dashboardInfoSection {
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em;
  text-align: center;
}
.darkMode .dashboardInfoSection table {
  margin: 0 auto;
  border: 1px solid white;
  padding: 1em;
  border-collapse: collapse;
  width: 80%;
}
.darkMode .dashboardInfoSection table td {
  border: 1px solid white;
  padding: 1em;
}

.darkMode select, .darkMode textarea, .darkMode input {
  background-color: #151515;
  color:white;
}

.darkMode button {
  border: 3px solid #999;
  color:white;
  background-color: #151515;
}

.darkMode .landingIMG, .darkMode header img:not(.hover-shadow, .lightbox, .thumb) {
  filter:invert(1);
}

.darkMode h1, .darkMode h2, .darkMode h3, .darkMode label, .darkMode span, .darkMode p {
  color:white;
}

.amaticSCFont header {
  font-family: 'Amatic SC', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-weight: 700;
}
.amaticSCFont header h1 {
  font-size: 2.75em;
}
.amaticSCFont main {
  font-family: 'Amatic SC', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-weight: 400;
  font-size: 1.6em;
}
.amaticSCFont main a {
  font-family: 'Amatic SC', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-weight: 700;
  font-size: 1.1em;
}
.amaticSCFont footer {
  font-family: 'Amatic SC', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-weight: 700;
}
.carterOneFont header {
  font-family: 'Carter One', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-weight: 400;
}
.carterOneFont main {
  font-family: 'Carter One', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-weight: 400;
}
.carterOneFont main a {
  font-family: 'Carter One', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-weight: 400;
}
.carterOneFont footer {
  font-family: 'Carter One', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-weight: 400;
}
.holtwoodOneFont header {
  font-family: 'Holtwood One SC', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', serif;
  font-weight: 400;
}
.holtwoodOneFont main {
  font-family: 'Holtwood One SC', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', serif;
  font-weight: 400;
}
.holtwoodOneFont main a {
  font-family: 'Holtwood One SC', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', serif;
  font-weight: 400;
  font-size: 1.1em;
}
.holtwoodOneFont footer {
  font-family: 'Holtwood One SC', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', serif;
  font-weight: 400;
}
.mulishFont header {
  font-family: 'Mulish', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', sans-serif;
  font-weight: 900;
}
.mulishFont main {
  font-family: 'Mulish', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', sans-serif;
  font-weight: 400;
}
.mulishFont main a {
  font-family: 'Mulish', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', sans-serif;
  font-weight: 700;
}
.mulishFont footer {
  font-family: 'Mulish', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', sans-serif;
  font-weight: 900;
}
.pacificoFont header {
  font-family: 'Pacifico', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-weight: 400;
}
.pacificoFont main {
  font-family: 'Pacifico', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-weight: 400;
  font-size: 1.1em;
}
.pacificoFont main a {
  font-family: 'Pacifico', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-weight: 400;
  font-size: 1.25em;
}
.pacificoFont footer {
  font-family: 'Pacifico', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-weight: 400;
}
.radleyFont header {
  font-family: 'Radley', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', serif;
  font-weight: 400;
}
.radleyFont header h1 {
  font-size: 2.5em;
}
.radleyFont main {
  font-family: 'Radley', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', serif;
  font-weight: 400;
  font-size: 1.3em;
}
.radleyFont main a {
  font-family: 'Radley', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', serif;
  font-weight: 400;
  font-size: 1.1em;
}
.radleyFont footer {
  font-family: 'Radley', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', serif;
  font-weight: 400;
}
.sigmarOneFont header {
  font-family: 'Sigmar One', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-weight: 400;
}
.sigmarOneFont header h1 {
  font-size: 2.25em;
}
.sigmarOneFont main {
  font-family: 'Sigmar One', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-weight: 400;
  font-size: 1.1em;
}
.sigmarOneFont main a {
  font-family: 'Sigmar One', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-weight: 400;
  font-size: 1.1em;
}
.sigmarOneFont footer {
  font-family: 'Sigmar One', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-weight: 400;
}
.smytheFont header {
  font-family: 'Smythe', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-weight: 400;
}
.smytheFont header h1 {
  font-size: 2.75em;
}
.smytheFont main {
  font-family: 'Smythe', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-weight: 400;
  font-size: 1.6em;
}
.smytheFont main a {
  font-family: 'Smythe', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-weight: 400;
  font-size: 1.1em;
}
.smytheFont footer {
  font-family: 'Smythe', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-weight: 400;
}
.musettaFont header {
  font-family: 'Musetta', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.musettaFont header h1 {
  font-size: 2.75em;
}
.musettaFont main {
  font-family: 'Musetta', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.6em;
}
.musettaFont main a {
  font-family: 'Musetta', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.1em;
}
.musettaFont footer {
  font-family: 'Musetta', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.cassannetFont header {
  font-family: 'Cassannet', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.cassannetFont header h1 {
  font-size: 2.75em;
}
.cassannetFont main {
  font-family: 'Cassannet', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.6em;
}
.cassannetFont main a {
  font-family: 'Cassannet', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.1em;
}
.cassannetFont footer {
  font-family: 'Cassannet', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.bariolThinFont header {
  font-family: 'Bariol Thin', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.bariolThinFont header h1 {
  font-size: 2.75em;
}
.bariolThinFont main {
  font-family: 'Bariol Thin', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.6em;
}
.bariolThinFont main a {
  font-family: 'Bariol Thin', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.1em;
}
.bariolThinFont footer {
  font-family: 'Bariol Thin', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.bariolSerifThinFont header {
  font-family: 'Bariol Serif Thin', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.bariolSerifThinFont header h1 {
  font-size: 2.75em;
}
.bariolSerifThinFont main {
  font-family: 'Bariol Serif Thin', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.6em;
}
.bariolSerifThinFont main a {
  font-family: 'Bariol Serif Thin', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.1em;
}
.bariolSerifThinFont footer {
  font-family: 'Bariol Serif Thin', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.archiaFont header {
  font-family: 'Archia', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.archiaFont header h1 {
  font-size: 2.75em;
}
.archiaFont main {
  font-family: 'Archia', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.6em;
}
.archiaFont main a {
  font-family: 'Archia', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.1em;
}
.archiaFont footer {
  font-family: 'Archia', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.bouldFont header {
  font-family: 'Bould', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.bouldFont header h1 {
  font-size: 2.75em;
}
.bouldFont main {
  font-family: 'Bould', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.6em;
}
.bouldFont main a {
  font-family: 'Bould', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.1em;
}
.bouldFont footer {
  font-family: 'Bould', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.geomanistFont header {
  font-family: 'Geomanist', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.geomanistFont header h1 {
  font-size: 2.75em;
}
.geomanistFont main {
  font-family: 'Geomanist', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.6em;
}
.geomanistFont main a {
  font-family: 'Geomanist', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.1em;
}
.geomanistFont footer {
  font-family: 'Geomanist', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.knileBlackFont header {
  font-family: 'Knile Black', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.knileBlackFont header h1 {
  font-size: 2.75em;
}
.knileBlackFont main {
  font-family: 'Knile Black', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.6em;
}
.knileBlackFont main a {
  font-family: 'Knile Black', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.1em;
}
.knileBlackFont footer {
  font-family: 'Knile Black', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.nowayLightFont header {
  font-family: 'Noway Light', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.nowayLightFont header h1 {
  font-size: 2.75em;
}
.nowayLightFont main {
  font-family: 'Noway Light', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.6em;
}
.nowayLightFont main a {
  font-family: 'Noway Light', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.1em;
}
.nowayLightFont footer {
  font-family: 'Noway Light', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.parkingFont header {
  font-family: 'Parking', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.parkingFont header h1 {
  font-size: 2.75em;
}
.parkingFont main {
  font-family: 'Parking', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.6em;
}
.parkingFont main a {
  font-family: 'Parking', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.1em;
}
.parkingFont footer {
  font-family: 'Parking', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.salomeFont header {
  font-family: 'Salome', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.salomeFont header h1 {
  font-size: 2.75em;
}
.salomeFont main {
  font-family: 'Salome', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.6em;
}
.salomeFont main a {
  font-family: 'Salome', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.1em;
}
.salomeFont footer {
  font-family: 'Salome', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.silkaFont header {
  font-family: 'Silka', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.silkaFont header h1 {
  font-size: 2.75em;
}
.silkaFont main {
  font-family: 'Silka', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.6em;
}
.silkaFont main a {
  font-family: 'Silka', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.1em;
}
.silkaFont footer {
  font-family: 'Silka', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.wotfardFont header {
  font-family: 'Wotfard', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.wotfardFont header h1 {
  font-size: 2.75em;
}
.wotfardFont main {
  font-family: 'Wotfard', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.6em;
}
.wotfardFont main a {
  font-family: 'Wotfard', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
  font-size: 1.1em;
}
.wotfardFont footer {
  font-family: 'Wotfard', 'Oswald', 'ZCOOL KuaiLe', 'Namun Gothic', 'Yusei Magic', 'Almarai', 'Assistant', 'Source Sans Pro', 'Kanit', 'Inter', cursive;
}
.imgcolumn {
  float: left;
  width: 25%;
  max-width: 250px;
}
.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
}
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  width: 90%;
  max-width: 1200px;
}
.close {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}
.imageSlide {
  display: none;
}
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}
img.thumb {
  opacity: 0.6;
  max-width: 150px;
}
.active2,
.thumb:hover {
  opacity: 1;
}
img.hover-shadow {
  transition: 0.3s;
}
.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.caption-container {
  text-align: center;
  background-color: black;
  padding: 2px 16px;
  color: white;
}
.numbertxt {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}
@media screen and (max-height: 490px) {
  header {
    overflow: scroll;
  }
  /* Or change the height or whatever */
}
@media screen and (max-width: 376px) {
  body {
    height: 100%;
  }
  header img {
    max-height: 5vh;
  }
  header .drawer {
    background-image: url("https://imagizer.imageshack.com/img924/8924/guCB73.jpg");
    background-position: top;
    background-size: cover;
  }
  main {
    overflow-x: hidden;
    overscroll-behavior: contain;
    -webkit-overscroll-behavior: contain;
    -webkit-overflow-scrolling: touch;
  }
  .entryChartHolder {
    flex-direction: column;
  }
  .homepageImageHolder {
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
  }
  .vicLine {
    width: 275px;
    height: 300px;
    margin: 0 auto 30px;
  }
  img.thumb {
    opacity: 0.6;
    max-width: 80px;
  }
}
@media screen and (max-width: 199px) {
  .linkSquare {
    overflow: scroll;
  }
  /* Or change the height or whatever */
}

.toggleDark {
  filter:invert(1);
  background: black;
}